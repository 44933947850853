import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import cn from 'classnames';

import {addFavoriteCardAction, addMaterialAction, removeFavoriteCardAction, removeMaterialAction} from 'redux/content/contentAsyncActions';
import {IArticleCard} from 'redux/content/interfaces';

import {useLoadFile} from 'utils/hooks/useLoadFile';

import Book from 'assets/image/library/gabi_lib.png';
import Clock from 'assets/image/library/clock.svg';
import {ReactComponent as CheckImg} from 'assets/image/library/ok-circle.svg';

import FavoriteButton from './favorite-button';
import MaterialButton from './material-button';

import './styles.scss';

type Props = {
  card: IArticleCard;
  active?: boolean;
  checked?: boolean;
  disabledButtons?: boolean;
  proUser?: boolean;
  onClick: () => void;
}

export const ArticleCard = ({
  card,
  active = false,
  checked = false,
  disabledButtons = false,
  proUser,
  onClick
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    media,
    getFileUrl
  } = useLoadFile();
  const [cardData, setCardData] = useState<IArticleCard>(card);
  const [fullHeart, setFullHeart] = useState(card.favorite);
  const [isSave, setIsSave] = useState(card.material);
  const hideMaterials = process.env.REACT_APP_HIDE_MATERIALS === 'true';

  useEffect(() => {
    const newCard = Object.assign({}, card);

    if (newCard.tags) {
      newCard.tags = newCard.tags.replace(/,/g, ', ');
    }

    if (!cardData.pictureUrl && cardData.picture) {
      newCard.pictureUrl = getFileUrl(cardData.picture);
    }

    setCardData(newCard);
  }, [card]);
  useEffect(() => {
    if (!cardData.pictureUrl && cardData.picture && media[cardData.picture]) {
      cardData.pictureUrl = media[cardData.picture];
      setCardData(cardData);
    }
  }, [media]);
  useEffect(() => {
    setIsSave(card.material);
  }, [card.material]);

  useEffect(() => {
    setFullHeart(card.favorite);
  }, [card.favorite]);

  function changeFavorite() {
    setFullHeart(!fullHeart);
    if (!fullHeart) {
      dispatch(addFavoriteCardAction(card.id));
    } else {
      dispatch(removeFavoriteCardAction(card.id));
    }
  }

  function addToMaterials() {
    setIsSave(!isSave);
    if (!isSave) {
      dispatch(addMaterialAction(card.id));
    } else {
      dispatch(removeMaterialAction([card.id]));
    }
  }

  function renderActiveCard() {
    return <div className="article-area">
      <div className="article-area__card-place">
        <div className="article-area__title">
          {cardData.title}
        </div>
        <div className="article-area__content">
          <div className="article-area__active-card">
            <FavoriteButton favorite={fullHeart} activeCard changeFavorite={changeFavorite}/>
            {proUser && !hideMaterials
              ? <MaterialButton material={isSave} activeCard addToMaterials={addToMaterials}/>
              : null}
          </div>
        </div>
      </div>
    </div>;
  }

  function renderCheckedCard() {
    return <div className="article-area">
      <div className="article-area__card-place">
        <button className="article-area__card" onClick={onClick}>
          <div className="article-area__title">
            {cardData.title}
          </div>
          <div className="article-area__content">
            <div className="article-area__active-card">
              <CheckImg className="article-area__check-img"/>
            </div>
          </div>
        </button>
      </div>
    </div>;
  }

  function renderBasicCard() {
    return <div className="article-area">
      <div className="article-area__card-place">
        <button className="article-area__card" onClick={onClick}>
          <div className="article-area__title">
            {cardData.title}
          </div>
          <div className="article-area__content">
            <div className="article-area__card-picture">
              <img src={cardData.pictureUrl || Book} role="presentation" alt="" className="article-area__image"/>
            </div>
            <div className="article-area__information">
              <div className="article-area__description">
                {cardData.description}
              </div>
              <div className="article-area__category">
                {cardData.tags}
              </div>
            </div>
          </div>
        </button>
        <div className={cn('article-area__bottom',
          {'article-area__pro-bottom': proUser},
          {'article-area__disabled-bottom': disabledButtons})}>
          {proUser && <div className="article-area__add-btn">
            {!hideMaterials && <MaterialButton material={isSave} disabled={disabledButtons} addToMaterials={addToMaterials}/>}
          </div>}
          <ul className={cn('article-area__addition', {'article-area__pro-addition': proUser})}>
            <li className="article-area__addition-item">
              <div
                className="article-area__item-img"
                style={{backgroundImage: `url('${cardData.labelPictureUrl}')`}}
                title={cardData.labelText}
              />
            </li>
            {/* <li className="article-area__addition-item">*/}
            {/*  <div*/}
            {/*    className="article-area__item-img"*/}
            {/*    style={{backgroundImage: `url('${cardData.readingImageUrl || Clock}')`}}*/}
            {/*    title={cardData.readingName}*/}
            {/*  />*/}
            {/* </li>*/}
            <li className="article-area__addition-like article-area__addition-item">
              <FavoriteButton favorite={fullHeart} disabled={disabledButtons} changeFavorite={changeFavorite}/>
            </li>
          </ul>
        </div>
      </div>
    </div>;
  }

  if (active) {
    return renderActiveCard();
  }
  if (checked) {
    return renderCheckedCard();
  }

  return renderBasicCard();
};
